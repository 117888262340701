:root {
    --image-gallery-height: 90;
}

/* Убираем стандартное выделение выбранного элемента - оно плохо подходит для мобайла */
.ReactGridGallery_tile-viewport, .ReactGridGallery_tile-viewport > img {
    margin: 0 !important;
    height: calc(var(--image-gallery-height) * 1px) !important;
    width: auto !important;
}

/* Всегда показываем кнопку для выбора фото в галерее */
.ReactGridGallery_tile-icon-bar > div {
    visibility: visible !important;
}

/* Скрываем кнопку для выбора фото в галерее, когда запрещено удалять фото */
.gallery-parent.hide-select-button .ReactGridGallery_tile-icon-bar > div {
    visibility: hidden !important;
}